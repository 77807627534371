import * as React from "react"
import { graphql } from "gatsby"
import { mapEdgesToNodes, filterOutDocsWithoutSlugs, filterOutDocsPublishedInTheFuture } from "../lib/helpers"

import Layout from "../components/layout"
import { FeaturedPosts } from "../components/featured-posts"
import { Heading } from "../ziibo"
import SEO from "../components/seo"

const BlogPage = ({ data }) => {
	const postNodes = (data || {}).posts
		? mapEdgesToNodes(data.posts).filter(filterOutDocsWithoutSlugs).filter(filterOutDocsPublishedInTheFuture)
		: []

	return (
		<Layout>
			<SEO title="Blog" />
			<main style={{ margin: "24px auto", width: "80%" }}>
				<Heading as="h1">All Blog Posts</Heading>
				<div style={{ display: "flex", justifyContent: "space-around", flexWrap: "wrap" }}>
					{/* {postNodes.map((node) => {
						return <PostPreview post={node} />
					})} */}
					{/* <HeroPost post={postNodes[0]} /> */}
					<FeaturedPosts posts={postNodes} />
				</div>
			</main>
		</Layout>
	)
}

export default BlogPage

export const query = graphql`
	fragment SanityImage on SanityMainImage {
		crop {
			_key
			_type
			top
			bottom
			left
			right
		}
		hotspot {
			_key
			_type
			x
			y
			height
			width
		}
		asset {
			_id
		}
	}

	query BlogPageQuery {
		posts: allSanityPost(
			sort: { fields: [publishedAt], order: DESC }
			filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
		) {
			edges {
				node {
					id
					publishedAt
					mainImage {
						...SanityImage
						alt
					}
					title
					_rawExcerpt
					slug {
						current
					}
					authors {
						_key
						author {
							name
						}
					}
				}
			}
		}
	}
`
