import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import BasePortableText from "@sanity/block-content-to-react"
import clientConfig from "../../client-config"
import serializers from "./serializers"
import { buildImageObj } from "../lib/helpers"
import { imageUrlFor } from "../lib/image-url"
import { Heading, Text } from "../ziibo"
import { format } from "date-fns"

const StyledFeaturedPost = styled.div`
	& .row {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		width: 80%;
		margin: 0 auto;
		& img {
			margin-right: 24px;
		}

		& .content-container {
			flex-basis: 100%;

			@media (min-width: 63.9rem) {
				flex-basis: 50%;
			}
		}
	}
`
const FeaturedPost = ({ post }) => {
	return (
		<StyledFeaturedPost key={post.title} elevation={3}>
			<hr style={{ margin: "48px auto" }} />
			<Link to={`/blog/${post.slug.current}`}>
				<div className="row">
					<img
						src={imageUrlFor(buildImageObj(post.mainImage))
							.width(300)
							.height(Math.floor((9 / 16) * 300))
							.auto("format")
							.url()}
						alt={post.mainImage.alt}
					/>
					<div className="content-container">
						<Heading fontSize={400} color="primary" shade="dark">
							{post.title}
						</Heading>
						<div style={{ display: "flex", alignItems: "center", marginBottom: "2.4rem" }}>
							{post.authors.map((author) => {
								return (
									<Text fontSize={200} key={author._key} color="primary" shade="default">
										{author.author.name}
									</Text>
								)
							})}
							{post.publishedAt && (
								<>
									<Text ml={1.2} mr={1.2}>
										|
									</Text>
									<Text fontSize={200}>{format(new Date(post.publishedAt), "MMMM do, yyyy")}</Text>
								</>
							)}
						</div>
						<BasePortableText blocks={post._rawExcerpt} serializers={serializers} {...clientConfig.sanity} />
					</div>
				</div>
			</Link>
		</StyledFeaturedPost>
	)
}
const FeaturedPosts = ({ posts }) => {
	return (
		<div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
			{posts.map((post) => {
				return <FeaturedPost post={post} />
			})}
		</div>
	)
}

export { FeaturedPosts }
